import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import find from 'lodash/find'
// import PageContent from '../content/PageContent'
import Markdown from 'markdown-to-jsx'

const Banner = ({ name, banners: { nodes: banners } }) => {
  const banner = find(banners, ['name', name])

  if (!banner) {
    return null
  }

  return (
    <figure className="banner" style={{ backgroundImage: `url('${banner.background}')` }}>
      <Link to={banner.link} className="link-unstyled">
        {banner.blend && (
          <div
            className="banner__blend"
            // style={{ opacity: banner.blend }}
          />
        )}
        <figcaption>
          <h2>{banner.text}</h2>
          <h3>
            <Markdown children={banner.subtext} />
            {/*&nbsp;<i className="fa fa-long-arrow-right" aria-hidden="true"></i>*/}
          </h3>
        </figcaption>
      </Link>
    </figure>
  )
}

export default ({ name = '' }) => (
  <StaticQuery
    query={graphql`
      {
        banners: allBannersYaml {
          nodes {
            name
            background
            link
            text
            subtext
            blend
          }
        }
      }
    `}
    render={data => <Banner name={name} {...data} />}
  />
)
