import React from 'react'
import Children from 'react-children-utilities'
import chunk from 'lodash/chunk'
import isObject from 'lodash/isObject'
import filter from 'lodash/filter'
import kebabCase from 'lodash/kebabCase'
import Link from '../Link'
import classNames from 'classnames'
import styles from './post-elements.module.sass'

export const Base = ({ children }) => <p className={styles.base}>{children}</p>

export const OpenAI = ({ children, input, output }) => (
  <p className={classNames(styles.openAi, { input: input, output: output })} data-label={(input && 'Prompt') || (output && 'Response')}>
    {children}
  </p>
)

export const Video = ({ src }) => {
  return (
    <div className="p-3 mb-3 bg-light">
      <video width="100%" autoplay="1" loop="1" muted="1">
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  )
}

export const ProsCons = ({ children }) => (
  <div className={classNames(styles.prosCons)}>
    {chunk(filter(children, isObject), 2).map((child, index) => (
      <div className={index === 0 ? 'pros' : 'cons'}>
        <>{child}</>
      </div>
    ))}
  </div>
)

export const CaseStudy = ({ children }) => <div className="case-study-paragraph">{children}</div>

export const LearnMore = ({ children }) => <div className={styles.learnMore}>{children}</div>

function replaceProTip(children) {
  return React.Children.map(children, child =>
    typeof child === 'string' && child.match(/PRO\s*TIP:/) ? (
      <>
        <TipLabel />
        {child.replace(/PRO\s*TIP:/, '')}
      </>
    ) : child.props && child.props.children ? (
      React.cloneElement(child, { children: replaceProTip(child.props.children) })
    ) : (
      child
    )
  )
}

const TipLabel = () => <b className={styles.tipLabel}>PRO TIP:</b>

export const Box = ({ children, protip, imageRight }) => (
  <div className={classNames(styles.postBox, { protip: protip, 'image-right': imageRight })}>
    {protip && (
      <p>
        <TipLabel />
      </p>
    )}
    {replaceProTip(children)}
  </div>
)

export const Tags = ({ tags }) => (
  <div className={styles.postTags}>
    Tags:{' '}
    {tags
      .map(tag => (
        <Link to={`/tags/${kebabCase(tag)}/`} key={tag}>
          {tag}
        </Link>
      ))
      .reduce((prev, curr) => [prev, ', ', curr])}
  </div>
)

// const List = ({ children }) => {
//   // const className = Children.onlyText(children).length / children.length > 200 ? 'long' : null
//   // console.log(className, Children.onlyText(children).length)

//   const hasHeading = Children.deepFind(children, child => child.type === 'h3')

//   // console.log(hasHeading)
//   // className={hasHeading && 'has-heading'}
//   return <ul>{children}</ul>
// }

// const Ordered = ({ children }) => {
//   const long = Children.onlyText(children).length / children.length > 200
//   return <ol className={long && 'is-long'}>{children}</ol>
// }

export const Checks = props => {
  const tag = props.tag || 'h3'
  const Tag = tag
  const title = props.title
  const Title = title
  const children = React.Children.map(props.children, child => {
    if (React.isValidElement(child)) {
      // console.log(child.type.displayName, child.type, child)

      switch (child.type.displayName || child.type) {
        case tag:
          // return React.cloneElement(child, { ...child.props, children: child.props.children[0].replace(/(Step\s)*\d+(\.|\:)\s/g, '') })
          return (
            <Tag className="check" id={child.props.id}>
              {Children.onlyText(child)}
            </Tag>
          )
        case title:
          return <Title className="title">{Children.onlyText(child)}</Title>
        case '[Function: List]':
        case 'List':
        case 'ul':
          return child.props.children.length === 1 ? <span className="check">{Children.onlyText(child)}</span> : child
        default:
          return child
      }
    }
  })
  return <div className={styles.checks}>{children}</div>
}

export const Steps = props => {
  const tag = props.tag || 'h3'
  const Tag = tag
  const children = React.Children.map(props.children, child => {
    if (React.isValidElement(child)) {
      switch (child.type.displayName || child.type) {
        case tag:
          // return React.cloneElement(child, { ...child.props, children: child.props.children[0].replace(/(Step\s)*\d+(\.|\:)\s/g, '') })
          return (
            <Tag className="step" id={child.props.id}>
              {Children.onlyText(child).replace(/(Step\s)*\d+(\.|\:)\s/g, '')}
            </Tag>
          )
        // case 'h4':
        //   return <h4 className="step">{Children.onlyText(child).replace(/(Step\s)*\d+(\.|\:)\s/g, '')}</h4>
        default:
          return child
      }
    } else {
      return child
    }
  })
  return <div className={styles.steps}>{children}</div>
}
