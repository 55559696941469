import React, { useState } from 'react'
import Markdown, { compiler } from 'markdown-to-jsx'
import classNames from 'classnames'
import styles from './table-of-contents.module.sass'

export default function TableOfContents({ children, h2Only }) {
  const contents = []
  const [contentsCollapsed, setContentsCollapsed] = useState(true)

  compiler(children, {
    createElement(type, props, children) {
      if (type === 'h2') {
        contents.push(`- [${children}](#${props.id})\n`)
      }
      if (type === 'h3' && !h2Only) {
        contents.push(`  - [${children}](#${props.id})\n`)
      }
    }
  })

  return (
    <div className={classNames(styles.tableOfContents, contentsCollapsed && styles.contentsCollapsed)}>
      <span className={styles.contentsTitle}>Table of Contents</span>
      <Markdown className={styles.contents}>{contents.join('')}</Markdown>
      <button className={contentsCollapsed ? styles.contentsToggle : styles.contentsClose} onClick={() => setContentsCollapsed(!contentsCollapsed)}>
        {contentsCollapsed ? 'See Table of Contents' : 'Toggle'}
      </button>
    </div>
  )
}
