import React, { useEffect } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import find from 'lodash/find'
import styles from './post-aside.module.sass'
import { debounce } from '../utils'
import { setService } from '../Service'

import AsideBanner from './AsideBanner'
import AsideBox from './AsideBox'

const PostAside = ({ id, person, children, services: { nodes: services } }) => {
  const service = find(services, ['id', id]) || {}
  const childrenContent = (children || []).join('')
  const content = service ? service.aside || childrenContent : childrenContent

  useEffect(() => {
    setService(id)
  }, [id])

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      var rowFull = document.querySelector('.row-full')
      var aside = document.querySelector("[class*='--post-aside--']")
      // console.log(rowFull, aside)
      if (rowFull && aside) {
        aside.style.maxHeight = rowFull.offsetTop - 64 + 'px'
      }
    }, 1000)
    window.addEventListener('DOMContentLoaded', debouncedHandleResize)
    window.addEventListener('resize', debouncedHandleResize)
    document.fonts && document.fonts.ready.then(debouncedHandleResize)
    return _ => {
      window.removeEventListener('DOMContentLoaded', debouncedHandleResize)
      window.removeEventListener('resize', debouncedHandleResize)
    }
  }, [])

  return (
    <aside className={styles.postAside}>
      {service.banner ? <AsideBanner name={service.name} path={service.path} {...service.banner} person={person || service.banner.person} /> : <AsideBox content={content} />}
    </aside>
  )
}

export default ({ id = '', person, children }) => (
  <StaticQuery
    query={graphql`
      {
        services: allServicesYaml {
          nodes {
            id
            path
            name
            aside
            banner {
              person
              heading
              content
              cta
            }
          }
        }
      }
    `}
    render={data => <PostAside {...data} id={id} children={children} person={person} />}
  />
)
