import React from 'react'
import loadable from '@loadable/component'

import Markdown from 'markdown-to-jsx'
import Banner from '../../components/Banner/Banner'
import Link from '../Link'
import TableOfContents from './TableOfContents'

import classNames from 'classnames'
import styles from './post-content.module.sass'

import PostAside from './PostAside'
import Image from '../Image'
import { OpenAI, Video, Box, Checks, Steps, CaseStudy, LearnMore, Tags, ProsCons, Base } from './post-elements'

// import ImageCarousel from '../../components/Wrappers/ImageCarousel'
const LazyImageCarousel = loadable(() => import('../../components/Wrappers/ImageCarousel'))
const ImageCarousel = props => <LazyImageCarousel {...props} />
// import YouTube from './YouTubeVideo'
const LazyYouTubeVideo = loadable(() => import('./YouTubeVideo'))
const YouTube = props => <LazyYouTubeVideo {...props} />
// import PostQuote from './PostQuote'
const LazyPostQuote = loadable(() => import('./PostQuote'))
const PostQuote = props => <LazyPostQuote {...props} />
// import SharedSection from './SharedSection'
const LazySharedSection = loadable(() => import('./SharedSection'))
const SharedSection = props => <LazySharedSection {...props} />

export default function PostContent({ children, content, tags, siteUrl, path, title, images }) {
  const replaceRaci = text => {
    return text.replace(/:([a-zA-Z]):/g, (_, letter) => {
      return `<i class="raci-${letter.toLowerCase()}">${letter}</i>`
    })
  }

  return (
    <section className={classNames(styles.postContent, 'blog-post')}>
      <div className={classNames(styles.postContainer, 'container')}>
        <div className={classNames(styles.innerContent)}>
          <Markdown
            options={{
              wrapper: function any({ children }) {
                return children
              },
              overrides: {
                a: { component: Link },
                img: { component: Image, props: { images: images, objectFit: 'contain' } },
                p: {
                  component: props => {
                    return React.Children.toArray(props.children).some(child => child.type && child.type.name === 'Image') ? (
                      <figure {...props} />
                    ) : (
                      <p {...props} />
                    )
                  }
                },
                Aside: { component: PostAside },
                Banner,
                blockquote: { component: PostQuote, props: { siteUrl: siteUrl, path: path, title: title } },
                Blockquote: { component: PostQuote, props: { siteUrl: siteUrl, path: path, title: title } },
                LearnMore,
                ReadMore: { component: LearnMore },
                ProsCons,
                CaseStudy,
                Carousel: { component: ImageCarousel, props: { customClass: styles.postCarousel } },
                // Gist,
                Video,
                YouTube,
                TOC: { component: TableOfContents, props: { children: content || '' } },
                Steps,
                Checks,
                Box,
                Section: { component: SharedSection },
                OpenAI,
                Base
                // Tags: { component: Tags, props: { tags: tags } }
                // SlideShare,
                // Twitter,
              }
            }}
            children={replaceRaci(content) + ''}
          />
          {children}
          {tags && <Tags tags={tags} />}
        </div>
      </div>
    </section>
  )
}

// p: props => {
//   return props.children.some(child => typeof child.type === 'function') ? <figure {...props} /> : <p {...props} />
// },
