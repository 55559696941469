import React from 'react'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import meta from '../../content/meta/default.yml'

export default function PostHead({
  title = meta.title,
  title_serp,
  subtitle,
  keywords,
  description,
  siteUrl = meta.siteUrl,
  canonical_link,
  image,
  orig_path,
  path,
  robots = meta.robots,
  authors = []
}) {
  const prependSiteUrl = path => (/http/.test(path) ? path : siteUrl + path)

  const postStructuredData = {
    '@context': 'http://schema.org',
    '@type': ['Article', 'BlogPosting'],
    headline: title,
    image: prependSiteUrl(image),
    // datePublished: '2023-05-05T08:00:00+02:00',
    // dateModified: '2023-05-05T09:20:00+02:00',
    author: authors
      .map(author => ({
        '@type': 'Person',
        name: author.name,
        url: `https://www.softkraft.co/p/${author.id}`
      }))
      .concat({
        '@type': 'Organization',
        name: 'SoftKraft',
        url: 'https://www.softkraft.co/'
      })
  }

  return (
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <title>
        {title_serp || title}
        {subtitle ? ` - ${subtitle}` : ''}
      </title>

      {keywords && <meta name="keywords" content={keywords} />}

      <meta name="description" content={description} />

      <meta name="robots" content={robots} />

      <script type="application/ld+json">{JSON.stringify(postStructuredData)}</script>

      {meta.structuredData.map((item, index) => (
        <script key={index} type="application/ld+json">{JSON.stringify(item)}</script>
      ))}

      <meta name="twitter:site" content="soft_kraft" />
      <meta name="twitter:card" content="summary_large_image" />

      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={prependSiteUrl(orig_path || path)} />
      <meta property="og:image" content={prependSiteUrl(image)} />

      <link rel="canonical" href={prependSiteUrl(canonical_link || path)} />
    </Helmet>
  )
}

// https://snappywebdesign.net/blog/how-to-add-structured-data-to-blog-posts-in-gatsby/
