import React from 'react'
import styles from './aside-box.module.sass'
import Markdown from 'markdown-to-jsx'

const FakeButton = ({ children }) => <span className={styles.fakeButton}>{children}</span>

export default function AsideBox({ content }) {
  return (
    <Markdown
      className={styles.asideBox}
      options={{
        overrides: {
          FakeButton
        }
      }}
    >
      {content + '<FakeButton>See how we can help you</FakeButton>'}
    </Markdown>
  )
}
