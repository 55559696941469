import React from 'react'
import loadable from '@loadable/component'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Layout from '../layout'
import PostHead from '../layout/PostHead'
import PageHeader from '../components/Sections/PageHeader'
import PostHeader from '../components/Blog/PostHeader'
import PostContent from '../components/Blog/PostContent'
import PostAuthor from '../components/Blog/PostAuthor'
// import PostRelated from '../components/Blog/PostRelated'
const LazyPostRelated = loadable(() => import('../components/Blog/PostRelated'))
const PostRelated = props => <LazyPostRelated {...props} />

export default function BlogPost({ data: { post, images, avatars } }) {
  const siteUrl = 'https://www.softkraft.co'
  const { title, image, path, content, tags, service, authors, classes } = post

  return (
    <Layout>
      <PostHead {...post} />
      <PageHeader dark={false} />
      <article className="single-post">
        <PostHeader service={service} title={title} authors={authors} image={image} content={content} images={images} avatars={avatars} />
        <PostContent content={content} tags={tags} siteUrl={siteUrl} path={path} title={title} images={images} />
        <PostAuthor authors={authors} />
      </article>
      <PostRelated path={path} />
    </Layout>
  )
}

export const query = graphql`
  query BlogPostByID($id: String!, $directories: [String]!) {
    post: postsYaml(id: { eq: $id }) {
      id
      date(formatString: "MMMM DD, YYYY")
      title
      title_serp
      path
      classes
      service {
        id
        path
        name
      }
      authors {
        id
        name
        quote
        avatar
        position
        summary
        linkedin
      }
      image
      description
      tags
      content
    }
    images: allImageSharp(filter: { fields: { parentDir: { in: $directories } } }) {
      nodes {
        fluid {
          originalName
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    avatars: allImageSharp(filter: { parent: { internal: { description: { regex: "/avatar/" } } } }) {
      nodes {
        fluid {
          originalName
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

BlogPost.propTypes = {
  data: PropTypes.shape({
    post: PropTypes.shape({
      title: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
}
