import React from 'react'
import readingTime from 'reading-time'
import classNames from 'classnames'
import Markdown from 'markdown-to-jsx'

import Image from '../Image'
import Link from '../Link'
import styles from './post-header.module.sass'

const avatarPlaceholder =
  "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 480 480' style='enable-background:new 0 0 480 480' xml:space='preserve'%3E%3Ccircle style='fill:%23b8bac0' cx='240' cy='240' r='240'/%3E%3Cpath style='fill:%23fff' d='M240 360.07c-27.944 0-53.297-11.991-72.003-31.372-.014 11.615-.436 28.379-3.516 40.611 2.02 1.235 3.588 3.262 3.894 5.784 3.992 32.484 34.781 56.977 71.625 56.977 36.836 0 67.625-24.496 71.625-56.977.31-2.525 1.844-4.549 3.895-5.78-3.08-12.233-3.503-28.999-3.517-40.615-18.706 19.381-44.059 31.372-72.003 31.372z'/%3E%3Cpath style='fill:%23d7dbe0' d='M310.44 330.174c-18.549 18.477-43.242 29.896-70.44 29.896-27.944 0-53.297-11.991-72.003-31.372-.014 11.615-.436 28.379-3.516 40.611 2.02 1.235 3.588 3.262 3.894 5.784 1.765 14.359 8.778 27.144 19.223 36.954 48.168-6.782 102.839-54.345 122.842-81.873z'/%3E%3Cpath style='fill:%23fff' d='M312 160.07H176c-22.055 0-40 17.945-40 40v48c0 61.758 46.656 112 104 112s104-50.242 104-112v-56c0-17.644-14.352-32-32-32z'/%3E%3Cpath style='fill:%235c546a' d='M296 72.07H192c-15.047 0-27.695 10.438-31.102 24.449C133.359 100.02 112 123.598 112 152.07v40c0 20.617 8.752 39.851 24 53.52v-45.52c0-22.055 17.945-40 40-40h136c17.648 0 32 14.355 32 32v53.511c15.251-13.667 24-32.899 24-53.511v-48c0-39.699-32.297-72-72-72zM61.632 400.544C105.562 449.319 169.191 480 240 480s134.438-30.681 178.368-79.456c-7.66-10.356-18.462-18.77-32.352-22.659-.32-.09-.641-.16-.969-.207l-63.859-9.582c-.391-.059-1.227-.09-1.625-.09a7.998 7.998 0 0 0-7.938 7.023c-4 32.48-34.789 56.977-71.625 56.977-36.844 0-67.633-24.492-71.625-56.977-.5-4.129-4.219-7.234-8.141-7.02a6.821 6.821 0 0 0-1.422.086l-63.859 9.582a7.53 7.53 0 0 0-.969.207c-13.89 3.891-24.692 12.304-32.352 22.66z'/%3E%3C/svg%3E"

export default function PostHeader({ title, service, authors = [], image, content, images, avatars }) {
  return (
    <section className={styles.postHeader}>
      <div className="container">
        <div className="row cols-2">
          <div className={classNames(styles.headerContent, 'col-lg-6 offset-xxl-1 col-xxl-5')}>
            {service && (
              <div itemScope itemType="http://schema.org/BreadcrumbList">
                <div className={styles.breadcrumbList} itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                  <a className={styles.breadcrumbLink} href={service.path} itemProp="url">
                    <span itemProp="name">{service.name}</span>
                  </a>
                  <meta itemProp="position" content="1" />
                </div>
              </div>
            )}
            <Markdown
              className={styles.postTitle}
              options={{ wrapper: 'h1', forceWrapper: true }}
              children={title || ''}
            />
            <div className={styles.readingTime}>{readingTime(content).text}</div>
            <div className={styles.postAuthor}>
              <figure className={styles.authorImage}>
                {authors.map((author, i) => (
                  <Image
                    key={i}
                    src={author.avatar || avatarPlaceholder}
                    alt={author.name}
                    width={72}
                    height={72}
                    loading="eager"
                    images={avatars}
                    wrapper={false}
                  />
                ))}
              </figure>

              <span className={styles.authorName}>
                {authors.map((author, i) => (
                  <>
                    <Link key={i} to={author.profile || `/p/${author.id}/`}>
                      {author.name}
                    </Link>
                    {i !== authors.length - 1 && ', '}
                  </>
                ))}
              </span>
              {/* {author.quote && authors.length === 1 && <span className={styles.authorQuote}>{author.quote}</span>} */}
            </div>
          </div>

          <figure className={classNames(styles.headerImage, 'col-lg-6')}>
            <Image alt={title} src={image} loading="eager" images={images} />
          </figure>
        </div>
      </div>
    </section>
  )
}
